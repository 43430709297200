import React, { useContext, useEffect, useState } from 'react'
import { StateController } from './../../App'

import { getMaximumBlock } from '../../utils/functions'
import SectionPremioMayor from '../molecules/SectionPremioMayor'
import SectionTyc from '../molecules/SectionTyc'
import SectionAwards from '../molecules/SectionAwards'
import Container from '../../layouts/Container/Container'
import Preloader from '../atoms/Preloader'
import CustomModal from '../molecules/CustomModal'

function Awards() {
  const [awards, setAwards] = useState(false)
  const [canRedem, setCanRedem] = useState(false)
  const { context } = useContext(StateController)
  const [noGoal, setNoGoal] = useState(false)
  const [exhausted, setExhausted] = useState(false);
  console.log('exhausted',exhausted)
  useEffect(() => {
    if (context && context.awards) {
      setAwards(
        context.awards[`block_${getMaximumBlock(context.block, context.blocks)}`].awards
      )
      setCanRedem(
        context.awards[`block_${getMaximumBlock(context.block, context.blocks)}`].canRedem
      )
      setExhausted(
        context.awards[`block_${getMaximumBlock(context.block, context.blocks)}`].awards
      )
    }

  }, [context])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

useEffect(() => {  

    if (context && context.tracing && context.tracing.winner_1 === 0) {
      setNoGoal(true)
    }else{
      setNoGoal(false)
    }
  },[context])

  return (
    <div className='mc-page awards slow_ani'>

      {
        context ?
          <>
            <SectionAwards awards={awards} canRedem={canRedem} />

            <SectionPremioMayor />
            <Container className={'tyc'}>
              <SectionTyc />

            </Container>
          </>
          :
          <Preloader type={'fullscreen'} />
      }

      {
        ( noGoal) ?
        <CustomModal
          globalModal
          title="Aún no has cumplido tu meta"
          desc="Sigue usando tu tarjeta para ganar"
          cta="Aceptar"
          icon={false}
          primaryAction={
            {
              action: setNoGoal,
              buttonText: 'Cerrar',
              // loading: false
            }
          }
          show={noGoal} setShowModal={setNoGoal}
        />
        :
        <></>
      }

{
  exhausted?.length === 0 &&
      <CustomModal
        globalModal
        title="Los bonos se agotaron"
        desc="Ya no quedan bonos para redimir, pero sigue pagando con tu Tarjeta de Crédito Mastercard PacifiCard."
        cta="Aceptar"
        icon={false}
        primaryAction={
          {
            action: setExhausted,
            buttonText: 'Aceptar',
            loading: false
          }
        }
        show={exhausted} setShowModal={setExhausted}
      />
}
    </div>
  )
}
export default Awards