import Container from "../../layouts/Container/Container";

import React from 'react'

export const PreheaderAccelerator = () => {
  return (
    <div className="PreheaderAccelerator">
        <Container>
            <p className="txt-emoji">
            <b className="goal">¡Acelera tu camino a la meta! </b>Del 20 al 24 de diciembre <b>tus compras suman el doble a tu meta. 🚀</b>
            </p>
            <p className="txt">
            Además <b>podrás ganar un bono adicional</b> si eres el cliente que acumula mayor monto en compras durante estos días y cumple su meta.
            </p>
        </Container>
    </div>
  )
}
