import { 
  URL, 
  GLOBAL_CODE,
  APIKEY
} from "../constants/constants"
APIKEY
export const getUser = async ({ token }) => {
  const res = await fetch(`${URL}/userdata`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      'Content-Type': 'application/json',
      "x-api-key": APIKEY,
      'x-access-token': token,
      'x-access-campaign': GLOBAL_CODE
    },
    redirect: "follow",
    referrerPolicy: "no-referrer"
  })

  if (res) {
    
    return (res.json())
  }
}

export const getStaticContent = async () => {
  const res = await fetch(`${URL}/userdata?static=true`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      'Content-Type': 'application/json',
      "x-api-key": APIKEY,
      'x-access-campaign': GLOBAL_CODE
    },
    redirect: "follow",
    referrerPolicy: "no-referrer"
  })

  if (res) {
    
    return (res.json())
  }
}

export const getLoginsReport = async ({code, action, date}) => {
  const res = await fetch(`${URL}/dashboard`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      'Content-Type': 'application/json',
      "x-api-key": APIKEY,
      'x-access-campaign': GLOBAL_CODE,
      'x-access-code': code,
      'x-access-action': action
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify({
      filter: date
    })
  })

  if (res) {
    
    return (res.json())
  }
}
export const getLoginsReportUnique = async ({code, action, date}) => {
  const res = await fetch(`${URL}/dashboard`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      'Content-Type': 'application/json',
      "x-api-key": APIKEY,
      'x-access-campaign': GLOBAL_CODE,
      'x-access-code': code,
      'x-access-action': action
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify({
      filter: date
    })
  })

  if (res) {
    
    return (res.json())
  }
}
export const getRedemptionsReport = async ({code, action, date}) => {
  const res = await fetch(`${URL}/dashboard`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      'Content-Type': 'application/json',
      "x-api-key": APIKEY,
      'x-access-campaign': GLOBAL_CODE,
      'x-access-code': code,
      'x-access-action': action
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify({
      filter: date
    })
  })

  if (res) {
    
    return (res.json())
  }
}