import React, { useContext, useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import Slider from "react-slick"

import { StateController } from './../../App'
import AwardBox from '../molecules/AwardBox'
import { itsFiltered }  from '../../utils/functions'
// import Button from '../atoms/Button'

function AwardsGallery({
  awardBoxType,
  itemsPerLine,
  limit,
  limitButtonText,
  list,
  canRedem,
  termText,
  termLink,
  termModal,
  redemtionText,
  steps,
  showStock,
  download,
  twoStepAuth,
  variant
}) {
  const {context} = useContext(StateController)
  const [opts, setOpts] = useState([])
  const [currentGallery, setCurrentGallery] = useState(false)

  useEffect(() => {
    if (context && context.awards) {
      setOpts(
        Object.keys(context.awards).map((key) => context.awards[key])
      )
    }
  }, [context])

  const stepper_settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    customPaging: function(i) {
      return (
        <span key={i}>
          <p>{`Mes ${i+1}`}</p>
        </span>
      )
    }
  }

  const galleryToggler = (e) => {
    setCurrentGallery(e.target.value)
  }

  return (
    <div className={`mc-awards-container mc-awards-column-${itemsPerLine}`}>

      {context && context.allAwards && context.allAwards[0] && 
        <div className="mc-awards-container__selector">
          <select name="gallery-selector" id="gallery-selector" value={currentGallery} onChange={galleryToggler}>
            <option value="">Filtrar por valor</option>
            {itsFiltered(20000, context.availableForRedemtion) && <option value="20000">$20.000</option>}

          </select>
        </div>
      }
        
      {steps
        ? <div className="mc-awards-container__steper">
            <Slider {...stepper_settings}>
              {opts.map((step, index) => {

                return (
                  <div className="mc-awards-container__items" key={index}>
                    {step.awards.map((item, index) => {
                      
                      return (
                        <div key={index} className={`mc-awards-container__items--item type-${awardBoxType} ${variant ? 'variant' : ''}`}>
                          <AwardBox                          
                            type={awardBoxType}
                            award={item}
                            canRedem={step.canRedem ? step.canRedem : false}
                            termLink={termLink}
                            termText={termText}
                            termModal={termModal}
                            redemtionText={redemtionText}
                          />
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </Slider>
          </div>
        : list && list[0] 
        ? <div className="mc-awards-container__items">
            {list.filter((item, i) => {

              return limit ? i < limit ? true : false : true
            }).map((item, index) => {

              if (download && item.prepurchased === 1) item.prepurchased = true
              if (download && item.prepurchased === 0) item.prepurchased = false

              return (
                <div key={index} className={`mc-awards-container__items--item type-${awardBoxType} ${variant ? 'variant' : ''}`}>
                  <AwardBox                  
                    index={index}
                    type={awardBoxType}
                    award={item}
                    canRedem={canRedem}
                    termLink={termLink}
                    termText={termText}
                    termModal={termModal}
                    redemtionText={redemtionText}
                    showStock={showStock}
                    download={download}
                    twoStepAuth={twoStepAuth}
                  />
                </div>
              )
            })}
          </div>
        : <>
          <div style={{maxWidth:'253px',margin: '0 auto'}} className='btnNotRedeem-award-gallery'>
          {/* <Button type={'secondary'} link={'/premios'} status={'active'} text={'Ir a premios'} /> */}
          </div>
        </>
      }

      {limit && limitButtonText && (list && list[0]) && 
        <div className="mc-awards-container__actions">
          <Link to="/premios">{limitButtonText}</Link>
        </div>
      }
    </div>
  )
}

export default AwardsGallery